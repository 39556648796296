import request from '@/utils/request'

// 查询页面统计数据
export function queryPcbaStat(query) {
  return request({
    url: '/pcba/pcba/queryPcbaStat',
    method: 'get',
    params: query
  })
}

// 查询人员测试列表
export function listPcba(query) {
  return request({
    url: '/pcba/pcba/list',
    method: 'get',
    params: query
  })
}

// 查询人员测试分页
export function pagePcba(query) {
  return request({
    url: '/pcba/pcba/page',
    method: 'get',
    params: query
  })
}

// 查询人员测试详细
export function getPcba(data) {
  return request({
    url: '/pcba/pcba/detail',
    method: 'get',
    params: data
  })
}

// 新增人员测试
export function addPcba(data) {
  return request({
    url: '/pcba/pcba/add',
    method: 'post',
    data: data
  })
}

// 修改人员测试
export function updatePcba(data) {
  return request({
    url: '/pcba/pcba/edit',
    method: 'post',
    data: data
  })
}

// 删除人员测试
export function delPcba(data) {
  return request({
    url: '/pcba/pcba/delete',
    method: 'post',
    data: data
  })
}
